<template>
   <base-section
      id="calculation"
      space="5"
    >
   <v-container
            fluid
        >
        <v-card
        outlined>
        <v-tabs
            v-model="tab"
            background-color="secondary"
            centered
            dark
            icons-and-text
            dense
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              KALKULATOR
              <v-icon>mdi-calculator</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
              E-POLIS
              <v-icon>mdi-file-document</v-icon>
            </v-tab>
          </v-tabs>
         <v-tabs-items v-model="tab">
         <v-tab-item value="tab-1">
         <v-card-title class="body-2 font-weight-bold">
              TAKAFUL KENDARAAN BERMOTOR - KALKULATOR
            </v-card-title>
            <v-layout>
            <v-row>
            <v-col
            cols ="12"
            md   ="4">
              <v-text-field
                  v-money="format"
                  v-model.lazy="form.tsi"
                  ref="tsi"
                  label="Harga Kendaraan  *"
                  class="mx-2"
                  prepend-inner-icon="mdi-cash"
                  required="required"
                  :readonly="form.calculate === true ? true:false"
                  :filled="form.calculate === true ? true:false"
                  @input="GetData('tsi')"
                />
            </v-col>
            <v-col
            cols ="12"
            md   ="4">
              <v-text-field
                  v-model="form.wilayah"
                  label="Nomor Polisi *"
                  class="mx-2"
                  prepend-inner-icon="mdi-menu-down"
                  :prefix="form.plat"
                  required="required"
                  :readonly="true"
                  @click="GetData('platnomor')"
                />
            </v-col>
            <v-col
            cols ="12"
            md   ="4">
              <v-text-field
                  v-model="form.year"
                  label="Tahun Kendaraan *"
                  class="mx-2"
                  prepend-inner-icon="mdi-calendar-month"
                  :suffix="form.age"
                  required="required"
                  @change="GetData('age')"
                />
            </v-col>
            <v-col
            cols ="12"
            md   ="6">
              <v-select
                  v-model="form.produk"
                  label="Produk *"
                  class="mx-2"
                  prepend-inner-icon="mdi-clipboard-list-outline"
                  :items="item.produk"
                  item-text="desc"
                  item-value="id"
                  persistent-hint
                  return-object
                  single-line
                  @change="GetData('produk')"
                />
            </v-col>
            <v-col
            cols ="12"
            md   ="4">
              <v-select
                  v-model="form.tipe"
                  label="Jenis Kendaraan *"
                  class="mx-2"
                  prepend-inner-icon="mdi-view-list"
                  :items="item.tipe"
                  item-text="desc"
                  item-value="id"
                  persistent-hint
                  return-object
                  single-line
                  @change="GetData('tipe')"
                />
            </v-col>
            <v-col
            cols ="12"
            md   ="2">
              <v-text-field
                  v-model="form.period"
                  label="Periode (Tahun) *"
                  class="mx-2"
                  prepend-inner-icon="mdi-calendar"
                  required="required"
                  @change="GetData('period')"
                />
            </v-col>
            </v-row>
            </v-layout>
            <template v-if="form.calculate" class="text-center">
              <div class="mx-2">
                <strong>SILAHKAN PILIH COVERAGE *</strong>
                <table class="table table-responsive table-newcustom" width="85%">
                  <thead>
                    <tr>
                      <th></th>
                      <th width="5%">%</th>
                      <th width="10%">RATE</th>
                      <th width="15%">COVERAGE *</th>
                      <th width="12%">TSI</th>
                      <th>KONTRIBUSI</th>
                      <th width="5%">LOADING</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="n in form.number" :key="n">
                      <td>TAHUN - {{n}}</td>
                      <td><input type="text" :value="form.depre[n]" class="form-text-readonly text-right" size="5"></td>
                      <td><input type="text" :value="form.rate[n]" class="form-text-readonly text-right" size="10"></td>
                      <td><select v-model="form.coverage[n]" class="form-select form-text-combo" @change="Coverage(n)"><option value="">Please Select</option><option v-for="row in item.coverage" :value="row" :key="row">{{row}}</option></select></td>
                      <td><input type="text" :value="form.detailtsi[n]" v-money="format" class="form-text-numeric text-right" :readonly="true"></td>
                      <td><input type="text" :value="form.contribution[n]" class="form-text-readonly text-right" size="35"></td>
                      <td><input type="text" :value="form.loading[n]" class="form-text-readonly text-right" size="15"></td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-right font-weight-black">TOTAL KONTRIBUSI</td>
                      <td><input type="text" :value="form.totalcontribution" class="form-text-readonly text-right" size="35"></td>
                    </tr>
                    <tr>
                      <td colspan="5" class="text-right font-weight-black">LOADING</td>
                      <td><input type="text" :value="form.totalloading" class="form-text-readonly text-right" size="35"></td>
                    </tr>
                     <tr>
                      <td colspan="5" class="text-right font-weight-black secondary--text">TOTAL YANG HARUS DIBAYAR</td>
                      <td><input type="text" :value="form.totalpayment" class="form-text-combo text-right" size="35"></td>
                    </tr>
                  </tbody>
                </table>
                <v-btn depressed small color="primary" @click="ReCalculate()" v-if="form.totalpayment !== 0">HITUNG ULANG</v-btn>
                </div>
            </template>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <v-card-title class="body-2 font-weight-bold">
                E-POLIS
                </v-card-title>
                  <v-layout>
                  <v-row>
                    <v-col
                      cols ="12"
                      md   ="6">
                     <v-text-field
                        v-model="form.branchclient_detail"
                        ref="outlet"
                        label="Outlet"
                        class="mx-2"
                        prepend-inner-icon="mdi-map-marker"
                        @click="OpenData('outlet')"
                        :readonly="form.branchclient_id === '00003'?false:true"
                      />
                    </v-col>
                    <v-col
                      cols ="12"
                      md   ="6">
                     <v-text-field
                        v-model="form.contractno"
                        ref="contractno"
                        label="No Kontrak"
                        prepend-inner-icon="mdi-ticket-confirmation"
                      />
                    </v-col>
                  </v-row>
                </v-layout>
                <v-btn depressed small color="fourth" @click="CheckBatch()" v-if="form.branchclient_id !== '' || form.contractno !== ''" class="ml-2 mb-2">CEK DATA</v-btn>
                <v-btn depressed small color="secondary" @click="ClearForm()" v-if="(form.branchclient_id !== '' || form.contractno !== '') && form.branchclient_id === '00003'" class="ml-2 mb-2">CLEAR FORM</v-btn>
            </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-container>
        <!-- Dialog Outlet Branch Client -->
        <v-dialog
        v-model="select.branchclient"
        hide-overlay
        fullscreen
        >
        <v-card>
        <v-layout>
        <v-flex xs12 sm12>
            <v-card flat>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="fourth"
                outlined
                @click="select.branchclient = false"
            >
                Batal
            </v-btn>
            </v-card-actions>
            <v-text-field class="mx-2" v-model="search.branchclient" placeholder="Cari Outlet dan Enter" id="filter_branchclient" append-icon="mdi-magnify" @keyup.enter="OpenData('outlet')"></v-text-field>
            <v-progress-linear indeterminate color="primary" v-if="loading.branchclient"></v-progress-linear>
            <v-list two-line class="pt-0">
            <span v-if="item.branchclient.length > 0">
                <template v-for="(branchclient, $index) in item.branchclient">
                <v-list-item :key="branchclient.branchclient_id" :data-num="$index + 1" ripple>
                    <v-list-item-content @click="SetData('outlet',branchclient.branchclient_id,branchclient.branchclient_name)">
                    <v-list-item-title>{{ branchclient.branchclient_id }} - {{ branchclient.branchclient_name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                    </v-list-item-action>
                </v-list-item>
                <v-divider v-if="$index + 1 < item.branchclient.length" :key="$index"></v-divider>
                </template>
            </span>
            <span v-else>
                <template v-if="item.branchclient.length >= 1">
                <v-list-item ripple>
                    <v-list-item-content>
                    <v-list-item-title>Tidak Ada Data</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                    </v-list-item-action>
                </v-list-item>
                </template>
            </span>
            </v-list>
            </v-card>
        </v-flex>
        </v-layout>
        </v-card>
        </v-dialog>
        <!-- End Dialog Branch Client -->
        <!-- Dialog Subjek -->
        <v-dialog
        v-model="select.batchsubject"
        width="820"
        persistent
        >
        <v-card>
        <v-layout>
        <v-flex xs12 sm12>
            <v-card flat>
            <v-card-actions class="primary white--text">
            {{form.branchclient_id}} - {{form.branchclient_name}}
            <v-spacer></v-spacer>
            <v-btn
                color="fourth"
                outlined
                @click="select.batchsubject = false"
            >
                Batal
            </v-btn>
            </v-card-actions>
            <!-- <v-text-field class="mx-2" v-model="search.branchclient" placeholder="Cari Outlet dan Enter" id="filter_branchclient" append-icon="mdi-magnify" @keyup.enter="OpenData('outlet')"></v-text-field> -->
            <v-progress-linear indeterminate color="primary" v-if="loading.batchsubject"></v-progress-linear>
            <v-list two-line class="pt-0">
            <span v-if="item.batchsubject.length > 0">
                <template v-for="(batchsubject, $index) in item.batchsubject">
                <v-list-item :key="batchsubject.subject_id" :data-num="$index + 1" ripple>
                    <v-list-item-content>
                    <v-list-item-title>{{ batchsubject.subject_id }}</v-list-item-title>
                    </v-list-item-content>
                     <v-list-item-action v-if="form.numbers === $index">
                      Loading...
                    </v-list-item-action>
                    <v-list-item-action v-else>
                      <v-icon large @click="PrintPDF($index,batchsubject.subject_id)">mdi-file-pdf-box</v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-divider v-if="$index + 1 < item.batchsubject.length" :key="$index"></v-divider>
                </template>
            </span>
            <span v-else>
                <v-list-item ripple>
                    <v-list-item-content>
                    <v-list-item-title>Tidak Ada Data</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                    </v-list-item-action>
                </v-list-item>
            </span>
            </v-list>
            </v-card>
        </v-flex>
        </v-layout>
        </v-card>
        </v-dialog>
        <!-- End Dialog Subjek -->
        <v-bottom-sheet v-model="select.platnomor">
            <v-list class="pt-0">
                <v-subheader class="primary white--text">Plat Nomor</v-subheader>
                <v-text-field
                  v-model="search.platnomor"
                  class="mx-2"
                  prepend-inner-icon="mdi-magnify"
                />
                <div class="scrollable">
                <v-list-item
                v-for="row in filter_platnomor"
                :key="row.gendtab_id"
                @click="Choose('platnomor',row.gendtab_id, row.gendtab_desc)"
                >
                <v-list-item-title class="text-center">{{ row.gendtab_id }}</v-list-item-title>
                </v-list-item>
                </div>
            </v-list>
        </v-bottom-sheet>
        <v-snackbar 
            :timeout="snackbar.timeout"
            :color="snackbar.color"
            v-model="snackbar.dialog"
            rounded = "pill"
            bottom
            >
            {{ snackbar.text }}
            <template>
            <v-btn text @click.native="snackbar.dialog = false">Close</v-btn>
            </template>
        </v-snackbar>
  </base-section>
</template>

<script>
  export default {
    name: 'KalkulatorKontribusi',
    data: () => ({
      form: {
        tsi: 0,
        plat: 'B',
        wilayah: 'WILAYAH 2',
        year: 0,
        age: '0 Tahun',
        produk: '',
        tipe: '',
        depre: {},
        rate: {},
        coverage:{},
        detailtsi:{},
        contribution:{},
        loading:{},
        period: 0,
        number: 0,
        totalcontribution:0,
        totalloading:0,
        totalpayment:0,
        calculate: false,
        branchclient_id: '',
        branchclient_name: '',
        numbers: '',
        contractno: ''
      },
      loading: {
          branchclient: false,
          batchsubject: false,
          print: false
      },
      format: {
          decimal: ',',
          thousands: '.',
          precision: 0
      },
      select: {
        platnomor: false,
        branchclient: false,
        batchsubject: false
      },
      item: {
        platnomor: [],
        produk: [
                {id:'CAR',desc:'TAKAFUL MOBIL'},
                {id:'MTR',desc:'TAKAFUL MOTOR'},
                ],
        tipe: [],
        tipecar: [
                {id:'CAR',desc:'MOBIL PENUMPANG'},
                {id:'TRUCK',desc:'TRUCK DAN PICK UP'},
                {id:'BUS',desc:'BUS'},
                ],
        tipemotor: [
                {id:'MTR',desc:'SEPEDA MOTOR'},
                ],
        coverage: [],
        coveragecar: [
          'TLO',
          'COMPRE'
        ],
        coveragemotor: [
          'TLO',
        ],
        branchclient: [],
        batchsubject: [],
      },
      search: {
        platnomor: '',
        branchclient: '',
        batchsubject: '',
      },
      //SnackBar
      snackbar: {
        dialog: false,
        color: 'success',
        text: '',
        timeout: 3000,
      },
      tab: null,
      login_user: ''
    }),
    provide: {
      heading: { align: 'center' },
    },
    created () {
      this.FirstLoad()
    },
    computed: {
      filter_platnomor () {
          const search_platnomor = this.search.platnomor.toLowerCase().trim()
          if (!search_platnomor) {
              return this.item.platnomor
          } else {
              return this.item.platnomor.filter(b => b.gendtab_id.toLowerCase().indexOf(search_platnomor) > -1)
          }
      },
    },
    methods: {
        FirstLoad () {
            let loginid = localStorage.getItem('website_users_id')
            if (loginid === null) {
              window.location.href = '/login'
            } else {
              this.login_user = loginid
              this.form.year = this.$functions.Years()
              if (loginid === 'pegadaian.pusat') {
                this.form.branchclient_id = ''
              } else {
                let otherid = localStorage.getItem('website_users_otherid')
                let othername = localStorage.getItem('website_users_othername')
                if (otherid !== '00003') {
                  this.form.branchclient_id = otherid
                  this.form.branchclient_name = othername
                  this.form.branchclient_detail = otherid + ' - ' + othername
                }
              }
            }
        },
        GetData (flag) {
          let period = this.form.period * 1
          if (flag === 'platnomor') {
              this.search.platnomor = ''
              let formdata = new FormData()
              formdata.append('gendtab_refid', 'V08')
              formdata.append('gendtab_actived', 'Y')
              formdata.append('order_by', 'gendtab_id')
              formdata.append('order_type', 'ASC')
              this.$axios.post(this.$functions.SafeURL('apiListGenTableSimple'),formdata,{
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
              })
              .then(response => {
                  this.select.platnomor = true
                  this.item.platnomor = response.data
                  this.ResetData()
              })
              .catch(e => {
                  this.SnackBar(true,'error',e.message,3000)
              })
          } else if (flag === 'age') {
             let ages = this.$functions.Years() - (this.form.year * 1)
             if (ages > 20) {
              alert('Usia kendaraan tidak boleh lebih dari 20 tahun')
              this.form.year = this.$functions.Years()
              this.form.suffix = '0 Tahun'
             } else {
              this.form.age = ages + ' Tahun' 
             }
             this.ResetData()
          } else if (flag === 'produk') {
             this.form.tipe = []
             if (this.form.produk.id === 'CAR') {
                this.item.tipe = this.item.tipecar
                this.item.coverage = this.item.coveragecar
             } else if (this.form.produk.id === 'MTR') {
                this.item.tipe = this.item.tipemotor
                this.item.coverage = this.item.coveragemotor
             }
             this.ResetData()
             this.form.calculate = false
             this.form.period = 0
          } else if (flag === 'tipe') {
             this.ResetData()
          } else if (flag === 'period') {
            let tsivalue = this.$functions.ReplaceAllString(this.form.tsi,'.','') * 1
            if (period === 0) {
              alert('Mohon isi periode')
              this.form.period = 0
              this.form.number = 0
            } else if (period > 5) {
              alert('Periode tidak boleh lebih dari 5 tahun')
              this.form.period = 0
              this.form.number = 0
            } else if (this.form.tsi === 0 || this.form.produk.id === undefined || this.form.tipe.id === undefined) {
              alert('Mohon isi harga, produk dan jenis kendaraan')
              this.form.period = 0
              this.form.number = 0
            } else {
              this.form.calculate =true
              this.form.number = period
              for (let i = 1;i<=period;i++) {
                let value = 100
                if (i === 2) {
                  value = 90
                } else if (i === 3) {
                  value = 80
                } else if (i === 4) {
                  value = 70
                } else if (i === 5) {
                  value = 60
                }
                let tsinumber = value * tsivalue /100
                this.$set(this.form.depre, i, value)
                this.$set(this.form.detailtsi, i, tsinumber)
              }
            }
          }
        },
        CalcTSI (period) {
          let tsivalue = this.$functions.ReplaceAllString(this.form.tsi,'.','') * 1
          for (let i = 1;i<=period;i++) {
              let depre = this.form.depre[i] * 1
              this.$set(this.form.detailtsi, i, (depre/100) * tsivalue)
          }
        },
        Coverage (number) {
          let coverage = this.form.coverage[number]
          let tsi = this.form.detailtsi[number]
          this.Calculate(number,coverage,tsi)
        },
        ResetData() {
          if (this.form.calculate === true) {
            let period = this.form.period * 1
            for (let i=1;i<=period;i++){
                this.$set(this.form.rate, i, 0)
                this.$set(this.form.contribution, i, 0)
                this.$set(this.form.loading, i, 0)
                this.$set(this.form.coverage, i, '')
            }
            this.form.totalcontribution = 0
            this.form.totalloading = 0
            this.form.totalpayment = 0
          }
        },
        Calculate (number,coverage,tsi) {
          let formData = new FormData()
          formData.append('fastflowrate_category', 'VEHICLE')
          formData.append('fastflowrate_id', this.form.tipe.id)
          formData.append('fastflowrate_type', 'MAIN')
          formData.append('fastflowrate_tsi', tsi)
          formData.append('fastflowrate_region', this.form.plat)
          formData.append('fastflowrate_period', 1)
          formData.append('fastflowrate_periodunit', 'Y')
          formData.append('fastflowrate_cover', coverage)
          this.$axios.post(this.$functions.SafeURL('apiListFastFlowRate', ``), formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
          })
          .then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
                this.$set(this.form.rate, number, response.data[0].fastflowrate_min)
                this.$set(this.form.contribution, number, this.$functions.FormatNumber(response.data[0].fastflowrate_cont_min,0))
                if (this.form.coverage[number] === 'COMPRE') {
                    let ages =  (this.$functions.Years() - (this.form.year * 1)) - 5
                    if (ages > 0) {
                      let loading = (ages * 0.05 * response.data[0].fastflowrate_min) * tsi / 100
                      this.$set(this.form.loading, number, this.$functions.FormatNumber(loading))
                    } else {
                      this.$set(this.form.loading, number, 0)
                    }
                } else {
                  this.$set(this.form.loading, number, 0)
                }
                this.SumContribution()
            }
          })
          .catch(e => {
              this.SnackBar(true,'error',e.message,3000)
          })
        },
        SumContribution () {
            let loading = 0
            let total = 0
            let period = this.form.period * 1
            for (let i=1;i<=period;i++){
              if (this.form.contribution[i] !== undefined) {
                total += this.$functions.ReplaceAllString(this.form.contribution[i],'.','') * 1
                if (this.form.loading[i] !== undefined && this.form.loading[i] !== 0) {
                  loading += this.$functions.ReplaceAllString(this.form.loading[i],'.','') * 1
                }
              }
            }
            this.form.totalcontribution = this.$functions.FormatNumber(total)
            this.form.totalloading = this.$functions.FormatNumber(loading)
            this.form.totalpayment = this.$functions.FormatNumber(total+loading)
        },
        Choose (flag, id, desc) {
          switch (flag) {
            case 'platnomor':
              this.form.plat   = id
              this.form.wilayah = desc
              this.select.platnomor = false
              break;
          }
        },
        ReCalculate () {
            this.form.tsi  = 0
            this.$refs.tsi.$el.getElementsByTagName('input')[0].value = 0
            this.form.plat = 'B'
            this.form.wilayah = 'WILAYAH 2'
            this.form.year = this.$functions.Years()
            this.form.age = '0 Tahun'
            this.form.produk = ''
            this.form.tipe = ''
            this.form.period = 0
            this.ResetData()
            this.form.calculate = false
        },
        SnackBar (dialog, color, text, timeout) {
            this.snackbar.dialog  = dialog
            this.snackbar.color   = color
            this.snackbar.text    = text
            this.snackbar.timeout = timeout
        },
        OpenData (data) {
          if (data === 'outlet' && this.form.branchclient_id === '00003') {
            this.item.branchclient = []
            this.select.branchclient = true
            setTimeout(function(){
              document.getElementById('filter_branchclient').focus()
            }, 500)
            if (this.search.branchclient !== '') {
              this.loading.branchclient = true
              let formData = new FormData()
              formData.append('datavalue', this.search.branchclient)
              formData.append('branchclient_group', 'PEGADAIAN')
              this.$axios.post(this.$functions.SafeURL('apiListBranchClient', ``), formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
              })
              .then(response => {
                let feedback = response.data
                if (feedback.length > 0) {
                    this.item.branchclient = feedback
                }
                this.loading.branchclient = false
              })
              .catch(e => {
                  this.loading.branchclient = false
                  this.SnackBar(true,'error',e.message,3000)
              })
            }
          }
        },
        SetData (flag, id, desc) {
          if (flag === 'outlet') {
            this.form.branchclient_id = id
            this.form.branchclient_name = desc
            this.form.branchclient_detail = id + ' - ' + desc
            this.select.branchclient = false
            this.CheckBatch()
          }
        },
        CheckBatch () {
          this.select.batchsubject =  true
          this.loading.batchsubject = true
          let formData = new FormData()
          formData.append('fastflow_refid', this.form.contractno)
          formData.append('fastflow_branchclient', this.form.branchclient_id)
          formData.append('fastflow_group', 'PEGADAIAN')
          this.$axios.post(this.$functions.SafeURL('apiListFastflowSubject', ``), formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
          })
          .then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
                if (feedback[0].feedback === 'Y') {
                  this.form.branchclient_id = feedback[0].branchclient_id
                  this.form.branchclient_name = feedback[0].branchclient_name
                  this.form.branchclient_detail = feedback[0].branchclient_id + ' - ' + feedback[0].branchclient_name
                  this.item.batchsubject = feedback
                } else {
                  this.SnackBar(true,'error',feedback[0].feedback,0)
                }
            } else {
              this.item.batchsubject = feedback
            }
            this.loading.batchsubject = false
          })
          .catch(e => {
              this.loading.batchsubject = false
              this.SnackBar(true,'error',e.message,3000)
          })
        },
        PrintPDF (index,id) {
          this.form.numbers = index
          let formData = new FormData()
          formData.append('fastflow_branchclient', this.form.branchclient_id)
          formData.append('fastflow_subject', id)
          this.$axios.post(this.$functions.SafeURL('apiListFastflowPrintSubject', ``), formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
          })
          .then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
                if (feedback[0].feedback === 'Y') {
                  window.open(feedback[0].subject_url)
                } else {
                  this.SnackBar(true,'error',feedback[0].feedback,3000)
                }
            } else {
              this.SnackBar(true,'error','No Data Found',3000)
            }
            this.form.numbers = ''
          })
          .catch(e => {
              this.form.numbers = ''
              this.SnackBar(true,'error',e.message,3000)
          })
        },
        ClearForm () {
          this.form.branchclient_id   = ''
          this.form.branchclient_name = ''
          this.form.branchclient_detail = ''
          this.form.contractno  = ''
        }
    },
  }
</script>
